import { Trans } from "@lingui/react";
import sortBy from "lodash/sortBy";
import type { GetServerSideProps } from "next";
import getConfig from "next/config";
import Image from "next/image";
import React, { type ComponentProps, type PropsWithChildren } from "react";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { z } from "zod";
import auraWBI from "~/Aura_WBI_haute_resolution.png";
import { Button } from "~/components/button";
import { Form, RHFLabeledSelect, RHFLabeledShortText } from "~/components/rhf";
import { Typography } from "~/components/typography";
import { STATIC_ROLES } from "~/constants/users";
import { env } from "~/env/server.mjs";
import feelInspired from "~/feel_inspired_blanc_haute_resolution.png";
import { useRouter } from "~/hooks/router";
//import { useLingui } from "@lingui/react";
import { i18n } from "~/i18nSetup";
import logoWBI from "~/logo_wbi_noir_haute_resolution.png";
import type { Role } from "~/types/database";
import { trpc } from "~/utils/trpc";
import { isRoleTypeDepartmentDependent, isRoleTypeServiceDependent, isRoleTypeSubDepartmentDependent } from "~/utils/user-role";
type SignInFormData = {
  roleType?: Role["type"];
  roleDepartmentId?: string;
  roleSubDepartmentId?: string;
  roleServiceId?: string;
  userId?: string;
  email?: string;
};
export const Layout: React.FC<PropsWithChildren> = ({
  children
}) => {
  return <div className="flex" data-sentry-component="Layout" data-sentry-source-file="auth.tsx">
      <div className="flex min-h-screen flex-1 flex-col items-center justify-center gap-40 bg-gradient-to-b from-slate-800 via-slate-800 to-[#342264] text-3xl sm:gap-20">
        <div className="relative max-w-[100px] lg:max-w-[115px] xl:max-w-[130px]">
          <Image src={logoWBI} alt="Logo WBI" className="relative h-[unset] w-full object-contain invert" data-sentry-element="Image" data-sentry-source-file="auth.tsx" />
        </div>
        <div className="relative max-w-[250px] lg:max-w-[275px] xl:max-w-[300px]">
          <Image src={auraWBI} alt="Aura Logo WBI" priority className="relative h-[unset] w-full object-contain" data-sentry-element="Image" data-sentry-source-file="auth.tsx" />
        </div>
        <div className="relative max-w-[150px] lg:max-w-[175px] xl:max-w-[200px]">
          <Image src={feelInspired} alt="feel inspired text" className="relative h-[unset] w-full object-contain" data-sentry-element="Image" data-sentry-source-file="auth.tsx" />
        </div>
      </div>
      <div className="flex flex-col flex-1 gap-4 justify-center items-center">{children}</div>
    </div>;
};
const RoleTypeField: React.FC = () => {
  const options = Object.entries(STATIC_ROLES).map(entry => ({
    id: entry[0],
    label: i18n._(entry[1])
  }));
  const sortedOptions = sortBy(options, "label");
  return <RHFLabeledSelect label={i18n._({
    id: "common.role",
    message: "Rôle"
  })} name="roleType" options={sortedOptions} data-sentry-element="RHFLabeledSelect" data-sentry-component="RoleTypeField" data-sentry-source-file="auth.tsx" />;
};
const RoleDepartmentField: React.FC<{
  fieldEnabled: boolean;
}> = ({
  fieldEnabled
}) => {
  const query = trpc.referential.departments.list.useQuery(undefined, {
    enabled: fieldEnabled
  });
  if (!fieldEnabled) {
    return null;
  }
  if (query.isLoading) {
    return "Loading...";
  }
  if (query.isError) {
    return "Une erreur est survenue";
  }
  const options = query.data.departments.map(({
    id,
    name
  }) => ({
    id,
    label: name
  }));
  return <RHFLabeledSelect label={i18n._({
    id: "common.department",
    message: "Département"
  })} name="roleDepartmentId" options={options} data-sentry-element="RHFLabeledSelect" data-sentry-component="RoleDepartmentField" data-sentry-source-file="auth.tsx" />;
};
const RoleSubDepartmentField: React.FC<{
  fieldEnabled: boolean;
}> = ({
  fieldEnabled
}) => {
  const query = trpc.referential.subDepartments.list.useQuery(undefined, {
    enabled: fieldEnabled
  });
  if (!fieldEnabled) {
    return null;
  }
  if (query.isLoading) {
    return "Loading...";
  }
  if (query.isError) {
    return "Une erreur est survenue";
  }
  const options = query.data.subDepartments.map(({
    id,
    name
  }) => ({
    id,
    label: name
  }));
  return <RHFLabeledSelect shouldUnregister={true} label={i18n._({
    id: "common.sub_department",
    message: "Sous-département"
  })} name="roleSubDepartmentId" options={options} data-sentry-element="RHFLabeledSelect" data-sentry-component="RoleSubDepartmentField" data-sentry-source-file="auth.tsx" />;
};
const RoleServiceField: React.FC<{
  fieldEnabled: boolean;
}> = ({
  fieldEnabled
}) => {
  const query = trpc.referential.services.list.useQuery(undefined, {
    enabled: fieldEnabled
  });
  if (!fieldEnabled) {
    return null;
  }
  if (query.isLoading) {
    return "Loading...";
  }
  if (query.isError) {
    return "Une erreur est survenue";
  }
  const options = query.data.services.map(({
    id,
    name
  }) => ({
    id,
    label: name
  }));
  return <RHFLabeledSelect shouldUnregister={true} label={i18n._({
    id: "common.service",
    message: "Service"
  })} name="roleServiceId" options={options} data-sentry-element="RHFLabeledSelect" data-sentry-component="RoleServiceField" data-sentry-source-file="auth.tsx" />;
};
const UserField: React.FC<{
  fieldEnabled: boolean;
}> = ({
  fieldEnabled
}) => {
  const methods = useFormContext<SignInFormData>();
  const roleFilter = {
    type: useWatch<SignInFormData>({
      name: "roleType"
    }),
    departmentId: useWatch<SignInFormData>({
      name: "roleDepartmentId"
    }),
    subDepartmentId: useWatch<SignInFormData>({
      name: "roleSubDepartmentId"
    }),
    serviceId: useWatch<SignInFormData>({
      name: "roleServiceId"
    })
  };
  const query = trpc.users.list.useQuery({
    filters: {
      role: roleFilter
    }
  }, {
    enabled: fieldEnabled
  });
  if (!fieldEnabled) {
    return null;
  }
  if (query.isLoading) {
    return "Loading...";
  }
  if (query.isError) {
    return "Une erreur est survenue";
  }
  const options = query.data.users.map(({
    email,
    full_name
  }) => ({
    id: email,
    label: full_name
  }));
  return <RHFLabeledSelect shouldUnregister={true} rules={{
    onChange: e => methods.setValue("email", e.target.value)
  }} label={i18n._({
    id: "common.user",
    message: "Utilisateur"
  })} name="userId" options={options} data-sentry-element="RHFLabeledSelect" data-sentry-component="UserField" data-sentry-source-file="auth.tsx" />;
};
const SignInPage: React.FC<{
  postLoginPath: string;
  kcIdpHint?: string;
}> = ({
  postLoginPath,
  kcIdpHint
}) => {
  const router = useRouter();
  const methods = useForm<SignInFormData>({
    defaultValues: {}
  });
  const {
    publicRuntimeConfig
  } = getConfig();
  const isProduction = publicRuntimeConfig.APP_ENV === "production";
  const roleType = useWatch({
    name: "roleType",
    control: methods.control
  });
  const roleTypeIsDefined = !!roleType;
  const roleDepartmentIsDefined = !!useWatch({
    name: "roleDepartmentId",
    control: methods.control
  });
  const roleSubDepartmentIsDefined = !!useWatch({
    name: "roleSubDepartmentId",
    control: methods.control
  });
  const roleServiceIsDefined = !!useWatch({
    name: "roleServiceId",
    control: methods.control
  });
  const emailIsNotEmpty = !!useWatch({
    name: "email",
    control: methods.control
  });
  const shouldSpecifyDepartment: boolean = roleTypeIsDefined && isRoleTypeDepartmentDependent(roleType);
  const shouldSpecifySubDepartment: boolean = roleTypeIsDefined && isRoleTypeSubDepartmentDependent(roleType);
  const shouldSpecifyService: boolean = roleTypeIsDefined && isRoleTypeServiceDependent(roleType);
  const shouldSpecifyUser: boolean = roleTypeIsDefined && (!shouldSpecifyDepartment && !shouldSpecifySubDepartment && !shouldSpecifyService || shouldSpecifyDepartment && roleDepartmentIsDefined || shouldSpecifySubDepartment && roleSubDepartmentIsDefined || shouldSpecifyService && roleServiceIsDefined);
  const canSubmit = emailIsNotEmpty;
  const loginMutation = trpc.auth.testingUsers.login.useMutation({
    onSuccess: () => {
      router.push(postLoginPath);
    }
  });
  return <Layout data-sentry-element="Layout" data-sentry-component="SignInPage" data-sentry-source-file="auth.tsx">
      {!isProduction && <>
          <Form className="mx-auto flex max-w-sm flex-col items-center justify-center gap-8" onSubmit={(data, _event) => {
        if (data.email) {
          loginMutation.mutate({
            email: data.email
          });
        }
      }} methods={methods}>
            <div className="flex flex-col w-full gap-4">
              <Typography.h4>
                <Trans id="auth.select_user_based_on_role" message="Sélectionner l'utilisateur sur base de son rôle" />
              </Typography.h4>
              <RoleTypeField />
              <RoleDepartmentField fieldEnabled={shouldSpecifyDepartment} />
              <RoleSubDepartmentField fieldEnabled={shouldSpecifySubDepartment} />
              <RoleServiceField fieldEnabled={shouldSpecifyService} />
              <UserField fieldEnabled={shouldSpecifyUser} />
            </div>
            <div className="flex flex-col gap-4">
              <Typography.h4>
                <Trans id="auth.select_user_based_on_email" message="Sélectionner l'utilisateur sur base de son email" />
              </Typography.h4>
              <RHFLabeledShortText label={i18n._({
            id: "common.email",
            message: "Email"
          })} name="email" />
            </div>
            <Button className="w-full" disabled={!canSubmit} data-testid="login-btn" variant="plain" type="submit">
              <Trans id="common.login" message="Connexion" />
            </Button>
          </Form>
          <span className="h-px w-full bg-neutral-400" />
        </>}
      <Typography.h2 fontWeight="700" data-sentry-element="unknown" data-sentry-source-file="auth.tsx">
        <Trans id="auth.log_in_to_your_account" message="Connectez-vous à votre compte" data-sentry-element="Trans" data-sentry-source-file="auth.tsx" />
      </Typography.h2>
      <Button variant="plain" onClick={() => {
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          kc_idp_hint: kcIdpHint
        }
      });
    }} data-sentry-element="Button" data-sentry-source-file="auth.tsx">
        <Trans id="common.log_in" message="Se connecter" data-sentry-element="Trans" data-sentry-source-file="auth.tsx" />
      </Button>
    </Layout>;
};
const ErrorPage: React.FC<{
  errorCorrelationId: string;
}> = ({
  errorCorrelationId
}) => {
  return <Layout data-sentry-element="Layout" data-sentry-component="ErrorPage" data-sentry-source-file="auth.tsx">
      <Typography.h4 data-sentry-element="unknown" data-sentry-source-file="auth.tsx">
        <Trans id="auth.login_attempt_failed" message="La tentative de connexion a échoué" data-sentry-element="Trans" data-sentry-source-file="auth.tsx" />
      </Typography.h4>
      <Typography.body data-sentry-element="unknown" data-sentry-source-file="auth.tsx">
        <Trans id="common.correlation_id" message="ID de corrélation:" data-sentry-element="Trans" data-sentry-source-file="auth.tsx" />
      </Typography.body>
      <Typography.error data-sentry-element="unknown" data-sentry-source-file="auth.tsx">{errorCorrelationId}</Typography.error>
      <Button variant="plain" href="/auth" className="mt-4" data-sentry-element="Button" data-sentry-source-file="auth.tsx">
        <Trans id="common.try_again" message="Réessayer" data-sentry-element="Trans" data-sentry-source-file="auth.tsx" />
      </Button>
    </Layout>;
};
type AuthPageProps = ({
  isError: true;
} & ComponentProps<typeof ErrorPage>) | ({
  isError: false;
} & ComponentProps<typeof SignInPage>);
const AuthPage: React.FC<AuthPageProps> = props => {
  return props.isError ? <ErrorPage {...props} /> : <SignInPage {...props} />;
};
const routeQuerySchema = z.object({
  error_correlation_id: z.string().optional(),
  force_keycloak_redirection: z.coerce.boolean().default(false),
  post_login_path: z.string(),
  kc_idp_hint: z.string().optional()
});
export const getServerSideProps: GetServerSideProps<AuthPageProps> = async context => {
  const {
    error_correlation_id: errorCorrelationId,
    post_login_path: postLoginPath,
    kc_idp_hint: kcIdpHint
  } = routeQuerySchema.parse(context.query);
  if (errorCorrelationId) {
    return {
      props: {
        isError: true,
        errorCorrelationId
      }
    };
  }
  if (!kcIdpHint) return {
    props: {
      isError: false,
      postLoginPath,
      kcIdpHint: env.KEYCLOAK_IDP_APPLICANT
    }
  };
  return {
    redirect: {
      destination: `${env.NEXT_APP_URL}/api/auth/login?post_login_path=${postLoginPath}&kc_idp_hint=${kcIdpHint}`,
      permanent: false
    }
  };
};
export default AuthPage;