import { useRouter as useNextRouter } from "next/router";
import type { ZodTypeAny, z } from "zod";

const useRouter = () => {
  const router = useNextRouter();
  if (!router.isReady) {
    throw new Error("Router is not ready. Make sure you wrap your app with RouterReadyGuard to ensure the router is always ready before rendering");
  }
  return router;
};

const useRouterQuery = <T extends ZodTypeAny>(schema: T) => {
  const { query } = useRouter();
  return schema.parse(query) as z.infer<T>;
};

export { useRouter, useRouterQuery };
